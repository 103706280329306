import { createRouter, createWebHistory } from 'vue-router'
import HoloFair from '../views/HoloFair.vue'
import SignIn from '../views/SignIn.vue'

const routes = [
  {
    path: '/',
    name: 'signin',
    component: SignIn,
    props: { autojoin: true }
  },
  {
    path: '/register',
    name: 'register',
    component: SignIn,
    props: { autojoin: false }
  },
  {
    path: '/holofair',
    name: 'holofair',
    component: HoloFair
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
